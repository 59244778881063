import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .img-and-text{
        display: flex;
    }

    .list-text{
        font-size: 15px;
    }

    .list-image{
        width: 30px;
        height: 30px;
        background: grey;
        background-repeat: none;
        background-size: cover;
        ${props => props.src? `background-image: url(${props.src})`: ''};
    }

    :hover{
        background: rgba(0, 0, 0, 0.05);
    }
`

function HerbListItem(props) {
    const {item} = props;
    const {pictures} = item;

    return (
        <Link to={`/ailment/${item.slug}`}>
            <Container >
                <div className="img-and-text">
     
                    <div className="list-text">
                        {item.name}
                    </div>
                </div>
            </Container>
        </Link>

    )
}

HerbListItem.defaultProps ={ 
    item: {
        name: "",
        pictures: [],
        slug: ""
    }
}


export default HerbListItem

