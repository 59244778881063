

import React from "react"
import Layout from "../components/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Ailments from "../components/Ailments"



const AilmentsPage = (props) => {
    let data = props.data.allMongodbHerbsAilments;
  return(
    <Layout seoProps={{title: "Ailments A-Z"}}>
        <Ailments data={data}  />
    </Layout>
  )
}


AilmentsPage.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
  }

export const getAllAilmetns = graphql`
query getAilments {
  allMongodbHerbsAilments(filter: {draft: {eq: false}, safeToPublish: {eq: true}}, sort: {fields: name, order: ASC}) {
      nodes {
        id
        slug
        mongodb_id
        name
      }
    
  }

}
`




export default AilmentsPage
